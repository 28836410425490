import { Card } from "./components/card/Card";
import { Footer } from "./components/footer/Footer";

function App() {
  
  return <>
    <main>
      <Card/>
    </main>
    <Footer/>

  </>;
}

export default App;
